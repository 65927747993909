import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss'],
})
export class StepProgressBarComponent {
  constructor(private _dataService: DataService) {}

  get index(): number {
    return this._dataService.index;
  }

  get steps(): readonly string[] {
    return this._dataService.steps;
  }

  get percent(): number {
    return (100 / (this.steps.length - 1)) * this._dataService.index;
  }

  setStep(index: number): void {
    if (environment.production) return;
    this._dataService.index = index;
  }
}
