import { TouchDetectionService } from 'src/app/services/touch-detection.service';
import { Component, inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  template: `<div
    class="tw-cursor-pointer"
    tooltipPosition="{{ tooltipPosition }}"
    tooltipEvent="{{ tooltipEvent }}"
    pTooltip="{{ message }}"
    tabindex="0"
  >
    <i class="pi {{ pIcon }}"></i>
  </div>`,
})
export class TooltipComponent implements OnInit {
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'bottom';
  @Input() pIcon: String = 'pi-info-circle';
  @Input() message: String = '';

  private _touchDetectionService = inject(TouchDetectionService);
  public tooltipEvent: 'focus' | 'hover' = 'hover';

  ngOnInit(): void {
    const isTouchDevice = this._touchDetectionService.isTouchDevice();
    this.tooltipEvent = isTouchDevice ? 'focus' : 'hover';
    console.info('Is touch device:', isTouchDevice);
  }
}
