import { DataService } from 'src/app/services/data.service';
import { Component, inject, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
})
export class FormPageComponent implements OnInit {
  private _viewportScroller: ViewportScroller = inject(ViewportScroller);
  private _dataService: DataService = inject(DataService);

  get index(): number {
    return this._dataService.index;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this._viewportScroller.scrollToPosition([0, 0]);
    }, 180);
  }
}
