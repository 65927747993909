import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public src = 'https://static.deamicis.cloud/logo/dea-logo-w.png';
  public env = environment;

  ngOnInit(): void {
    if (this.env.production === false) {
      this.src = 'https://static.deamicis.cloud/logo/dea-logo-b.png';
    }
  }
}
