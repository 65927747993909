import { ApiResponse } from '../models/api/api-response.model';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { API } from '../constants/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  private _http: HttpClient = inject(HttpClient);

  private _removeNulls(obj: any): any {
    if (obj === null || obj === undefined) return null;
    if (Array.isArray(obj)) {
      return obj
        .map((item) => this._removeNulls(item))
        .filter((item) => item !== null);
    } else if (typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const value = this._removeNulls(obj[key]);
        if (value !== null) acc[key] = value;
        return acc;
      }, {} as any);
    }
    return obj;
  }

  getPdfPreview(data: any): Observable<Blob> {
    data.users?.filter((user: any) => {
      delete user.ci;
      return user;
    });
    return this._http.post(
      API.enrollmentPdfPreview(),
      this._removeNulls(data),
      { responseType: 'blob' }
    );
  }

  postEnrollmentForm(formGroup?: FormGroup, test?: any) {
    return this._http.post<ApiResponse>(
      API.enrollment(),
      this._removeNulls(formGroup?.getRawValue() || test),
      { withCredentials: false }
    );
  }
}
