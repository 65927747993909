import { AbstractControl, FormControl } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-control-error-handler',
  templateUrl: './form-control-error-handler.component.html',
  styles: [],
})
export class FormControlErrorHandlerComponent {
  @Input() abstractControl?: FormControl | AbstractControl<any, any> | null;
  public debugMode: boolean = false;
}
