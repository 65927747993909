import { Self, Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[toUpperCase]',
})
export class InputUppercaseDirective {
  constructor(@Self() private input: NgControl) {}

  @HostListener('input', ['$event']) onInput($event: any) {
    $event.preventDefault();
    const target = $event.target as HTMLInputElement;
    this.input?.control?.setValue(target.value.toUpperCase());
  }
}
