<div *ngIf="abstractControl?.invalid && (abstractControl?.dirty || abstractControl?.touched)"
  class="text-danger tw-text-sm">
  <div *ngIf="abstractControl?.hasError('required')">Questo campo è obbligatorio</div>
  <div *ngIf="abstractControl?.hasError('minlength')">
    Questo campo prevede un input più lungo (Min {{abstractControl?.getError('minlength')?.requiredLength}} caratteri)
  </div>
  <div *ngIf="abstractControl?.hasError('maxlength')">
    Questo campo prevede un input più corto (Max {{abstractControl?.getError('maxlength')?.requiredLength}} caratteri)
  </div>
  <div *ngIf="abstractControl?.hasError('email') || abstractControl?.hasError('pattern')">
    Inserire un indirizzo e-mail valido
  </div>
  <div *ngIf="debugMode">Errors: {{abstractControl?.errors | json}}</div>
</div>
