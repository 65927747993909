<app-step-wrapper [title]="dataService.steps[4]">
  <div *ngIf="src === undefined"
    class="tw-rounded tw-overflow-clip tw-h-[480px] tw-flex tw-flex-col tw-justify-center tw-items-center">
    <app-spinner [size]="48"></app-spinner>
  </div>
  <div *ngIf="src !== undefined" class="tw-rounded tw-overflow-clip tw-h-[580px] tw-bg-neutral-200 tw-m-0 tw-p-0">
    <pdf-viewer class="tw-h-full tw-w-full" [src]="src" [render-text]="false" [original-size]="false"
      [show-borders]="true" [autoresize]="true">
    </pdf-viewer>
  </div>
  <div class="tw-mt-6 tw-flex tw-flex-row tw-justify-center">
    <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
      [routerLink]="'/creation'">
      <span class="tw-px-8 tw-py-4" style="color: inherit;">
        <strong style="color: inherit;">Conferma ed invia</strong>
      </span>
    </button>
  </div>
</app-step-wrapper>