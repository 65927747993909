<app-container>
  <div class="tw-py-8 tw-pt-12">
    <app-step-wrapper [title]="'Rette Scolastiche'">
      <p class="tw-mb-8">
        Prima di procedere con l’iscrizione, puoi consultare le rette scolastiche. Seleziona la sede e il relativo
        percorso di studi di tuo
        interesse per visualizzare tutte le informazioni relative ai costi e le modalità di pagamento.
      </p>
      <div class="tw-overflow-hidden">
        <p-tabView>
          <p-tabPanel *ngFor="let sede of sedi" [header]="sede.sede">
            <p-accordion>
              <div class="tw-overflow-hidden tw-flex tw-flex-col tw-gap-1">
                <div *ngFor="let dipartimento of sede.dipartimenti"
                  (click)="openPDF(dipartimento.nome,dipartimento.link)"
                  class="tw-border tw-rounded tw-flex tw-flex-row tw-gap-4 tw-p-4 tw-bg-slate-100 tw-cursor-pointer">
                  <div class="tw-font-bold tw-text-lg">
                    <i class="pi pi-chevron-right" style="font-size: 1rem"></i>
                  </div>
                  <div class="tw-font-bold tw-text-lg hover:tw-underline">
                    {{dipartimento.nome}}
                  </div>
                </div>
              </div>
            </p-accordion>
          </p-tabPanel>
        </p-tabView>
      </div>
      <div #controls controls class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-mt-6">
        <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
          [routerLink]="'/form'">
          <span class="tw-px-8 tw-py-4" style="color: inherit;">
            <strong style="color: inherit;">ISCRIVITI</strong>
          </span>
        </button>
      </div>
    </app-step-wrapper>
  </div>
</app-container>
<p-dialog [modal]="true" [draggable]="false" [dismissableMask]="true" [header]="dialogTitle" [(visible)]="visible"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '1000px' }">
  <div class="tw-rounded tw-overflow-clip tw-h-[480px] tw-bg-neutral-200 tw-m-0 tw-p-0">
    <pdf-viewer class="tw-h-full tw-w-full" [src]="src" [render-text]="false" [original-size]="false"
      [show-borders]="true" [autoresize]="true">
    </pdf-viewer>
  </div>
</p-dialog>
