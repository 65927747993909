import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  template: `<div [ngClass]="getClasses()">
    <ng-content></ng-content>
    <span
      *ngIf="optional"
      class="tw-ms-px tw-text-xs tw-font-light tw-opacity-75"
    >
      (Opzionale)
    </span>
  </div>`,
})
export class LabelComponent {
  @Input() optional: boolean = false;
  @Input() ngClass: string = '';

  getClasses() {
    return {
      'tw-text-lg': !this.ngClass.includes('tw-text-'),
      'tw-font-light': !this.ngClass.includes('tw-font-'),
      'tw-mb-2': !this.ngClass.includes('tw-mb-'),
      ...this.convertToClassObject(this.ngClass),
    };
  }

  private convertToClassObject(classes: string): { [key: string]: boolean } {
    return classes
      .split(' ')
      .reduce((acc, className) => ({ ...acc, [className]: true }), {});
  }
}
