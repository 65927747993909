import { DataService } from 'src/app/services/data.service';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss'],
})
export class SuccessPageComponent implements OnInit {
  private _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private _confirmationService = inject(ConfirmationService);
  private _dataService: DataService = inject(DataService);
  private _router: Router = inject(Router);

  public enrollmentClass?: string;
  public studentName?: string;
  public hash?: string;

  ngOnInit(): void {
    this._dataService.initializeEnrollmentFormSession();
    this._activatedRoute.queryParamMap.subscribe((query) => {
      this.hash = query.get('hash')?.toString().trim() || undefined;
      this.studentName = query.get('name')?.toString().trim() || undefined;
      this.enrollmentClass = query.get('class')?.toString().trim() || undefined;
    });
  }

  goToHomepage(event: Event): void {
    this._confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        'Sei sicuro di voler tornare alla home?<br/><span class="tw-font-light tw-text-xs">Attenzione: il codice della pratica non sarà più visibile</span>',
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'p-button-primary p-button-outlined',
      rejectButtonStyleClass: 'p-button-primary p-button-outlined',
      acceptLabel: 'Sì',
      rejectLabel: 'No',
      accept: () => {
        this._dataService.initializeEnrollmentFormSession();
        this._router.navigateByUrl('/');
      },
      reject: () => {},
    });
  }
}
