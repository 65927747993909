import { Component } from '@angular/core';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss'],
})
export class InfoPageComponent {
  public dialogTitle: string = '';
  public visible: boolean = false;
  public src: string = '';

  public sedi = [
    {
      sede: 'Istituti E. De Amicis - Milano',
      luogo: 'Milano',
      descrizione: '',
      dipartimenti: [
        {
          nome: 'Secondaria di I Grado',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/milano-scuola-secondaria-di-I-grado.pdf',
        },
        {
          nome: 'Liceo delle Scienze Umane',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/milano-liceo-delle-scienze-umane-licos.pdf',
        },
        {
          nome: 'Liceo Scientifico',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/milano-liceo-scientifico.pdf',
        },
        {
          nome: 'Liceo Scientifico Sportivo',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/milano-liceo-scientifico-sportivo.pdf',
        },
        {
          nome: 'Liceo Linguistico Internazionale',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/milano-liceo-linguistico-internazionale.pdf',
        },
        {
          nome: 'Liceo Classico',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/milano-liceo-classico.pdf',
        },
        {
          nome: 'ITEM',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/milano-item.pdf',
        },
      ],
    },
    {
      sede: 'Licei Santagostino - Gorgonzola',
      luogo: 'Gorgonzola',
      descrizione: '',
      dipartimenti: [
        {
          nome: 'Liceo Scientifico Sportivo',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/gorgonzola-liceo-scientifico-sportivo.pdf',
        },
        {
          nome: 'Liceo Linguistico Internazionale',
          link: 'https://static.deamicis.cloud/docs/rette-scolastiche/gorgonzola-liceo-linguistico-internazionale.pdf',
        },
      ],
    },
  ];

  openPDF(nome: string, link: string): void {
    this.src = link;
    this.dialogTitle = `Rette Scolastiche: ${nome}`;
    this.visible = true;
  }
}
