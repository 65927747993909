<app-step-wrapper [title]="dataService.steps[2]">
  <form [formGroup]="dataService.enrollmentForm" (click)="dataService.printForm()">
    <ng-container formArrayName="users">
      <ng-container *ngFor="let user of dataService.enrollmentFormNotStudentUsers; let last = last;">
        <app-user-form [uuid]="user?.get('__uuid')?.value"></app-user-form>
        <div *ngIf="!last" class="tw-mt-24"><app-hr></app-hr></div>
      </ng-container>
      <div class="tw-mt-8"></div>
      <app-hr *ngIf="dataService.enrollmentFormNotStudentUsers?.length !== 0"></app-hr>
      <div *ngIf="dataService.enrollmentFormNotStudentUsers?.length !== 0" class="tw-mb-8"></div>
      <app-form-grid>
        <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
          (click)="dataService.pushUser('Parent')" [disabled]="dataService.enrollmentFormParentUsersNumber >= 2">
          <span class="tw-p-4" style="color: inherit;">
            Aggiungi <strong style="color: inherit;">Genitore</strong>
          </span>
        </button>
        <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
          (click)="dataService.pushUser('Tutor')" [disabled]="dataService.enrollmentFormTutorNumber >= 2">
          <span class="tw-p-4" style="color: inherit;">
            Aggiungi <strong style="color: inherit;">Tutore</strong>
          </span>
        </button>
      </app-form-grid>
    </ng-container>
  </form>
</app-step-wrapper>