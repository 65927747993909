import { CreationPageComponent } from './pages/creation-page/creation-page.component';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { FormPageComponent } from './pages/form-page/form-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { InfoPageComponent } from './pages/info-page/info-page.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    title: 'Iscrizione Digitale - Istituti De Amicis',
    component: HomePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'informazioni',
    title: 'Informazioni - Istituti De Amicis',
    component: InfoPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'form',
    title: 'Iscrizione Digitale - Istituti De Amicis',
    component: FormPageComponent,
  },
  {
    path: 'creation',
    title: 'Creazione - Istituti De Amicis',
    component: CreationPageComponent,
  },
  {
    path: 'success',
    title: 'Success - Istituti De Amicis',
    component: SuccessPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
