<app-container>
  <div
    class="tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-min-h-[var(--dea-wrapper-height)]">
    <div class="tw-h-14 tw-w-14">
      <app-spinner *ngIf="!message.toLowerCase().includes('attenzione')" [size]="56"></app-spinner>
    </div>
    <div class="tw-w-full lg:tw-w-1/2">
      <p-progressBar [value]="value()" [style]="{ height: '16px' }" />
    </div>
    <div>{{message}}</div>
    <div *ngIf="message.toLowerCase().includes('attenzione')" class="tw-mt-6">
      <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
        (click)="retry()">
        <span class="tw-px-8 tw-py-4" style="color: inherit;">
          <strong style="color: inherit;">Riprova</strong>
        </span>
      </button>
    </div>
  </div>
</app-container>