import { DataService } from 'src/app/services/data.service';
import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-step-block-controller',
  template: `<div class="tw-text-2xl tw-mt-8">
    <div class="tw-flex tw-justify-center tw-mt-8">
      <span class="p-buttonset">
        <button
          pButton
          class="p-button-outlined"
          (click)="goToPrevIndex($event)"
          [disabled]="!canGoToPrevIndex"
          style="padding: 0.5rem 1.5rem; border-color: var(--dea-primary-color);"
        >
          <lucide-icon
            name="chevron-left"
            color="var(--dea-primary-color)"
          ></lucide-icon>
        </button>
        <button
          pButton
          class="p-button-outlined"
          (click)="goToNextIndex($event)"
          [disabled]="!canGoToNextIndex"
          style="padding: 0.5rem 1.5rem; border-color: var(--dea-primary-color);"
        >
          <lucide-icon
            name="chevron-right"
            color="var(--dea-primary-color)"
          ></lucide-icon>
        </button>
      </span>
    </div>
  </div>`,
})
export class StepBlockControllerComponent {
  constructor(
    private _dataService: DataService,
    private _viewportScroller: ViewportScroller
  ) {}

  get index(): number {
    return this._dataService.index;
  }

  get canGoToPrevIndex(): boolean {
    return this._dataService.canGoToPrevIndex();
  }

  get canGoToNextIndex(): boolean {
    return this._dataService.canGoToNextIndex();
  }

  goToPrevIndex(event: Event) {
    this._dataService.validateStep(this.index, event, false);
    this._dataService.goToPrevIndex();
    this.scrollToTop();
  }

  goToNextIndex(event: Event) {
    const isValid = this._dataService.validateStep(this.index, event, true);
    if (!isValid) return;
    this._dataService.goToNextIndex();
    this.scrollToTop();
  }

  scrollToTop() {
    this._viewportScroller.scrollToPosition([0, 0]);
  }
}
