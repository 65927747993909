import { environment } from 'src/environments/environment';

export class API {
  // ENROLLMENTS
  static enrollmentPdfPreview(): string {
    return `${environment.apiUrl}/v1/enrollment/pdf-preview`;
  }
  static enrollment(): string {
    return `${environment.apiUrl}/v1/enrollment`;
  }
  // TOOLS
  static dataFromCF(cf?: string): string {
    let url = `${environment.apiUrl}/v1/tools/cf/data?cf=${cf || 'CF-ERROR'}`;
    return url;
  }
  static CfFromData(data?: any): string {
    let url = `${environment.apiUrl}/v1/tools/cf/code?nome=${
      data.nome ?? '-'
    }&cognome=${data.cognome ?? '-'}&sesso=${data.sesso ?? '-'}&citta=${
      data.luogo_di_nascita ?? '-'
    }&provincia=${data.provincia_di_nascita ?? '-'}&anno=${
      data.data_di_nascita.split('-').at(0) ?? '-'
    }&mese=${data.data_di_nascita.split('-').at(1) ?? '-'}&giorno=${
      data.data_di_nascita.split('-').at(2) ?? '-'
    }`;
    return url;
  }
  static phonePrefixes(): string {
    return `${environment.apiUrl}/v1/tools/phone-prefixes`;
  }
}
