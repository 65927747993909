import { EnrollmentService } from 'src/app/services/enrollment.service';
import { ToolsService } from 'src/app/services/tools.service';
import { DataService } from 'src/app/services/data.service';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-creation-page',
  templateUrl: './creation-page.component.html',
  styleUrls: ['./creation-page.component.scss'],
})
export class CreationPageComponent implements OnInit {
  private _enrollmentService: EnrollmentService = inject(EnrollmentService);
  private _toolsService: ToolsService = inject(ToolsService);
  private _dataService: DataService = inject(DataService);
  private _router: Router = inject(Router);

  public message: string = 'Recupero delle informazioni in corso...';
  public steps: number = 0;
  public step: number = 0;
  public value = () => {
    return parseFloat(((100 / this.steps) * this.step).toFixed(2));
  };

  public data: any = {};

  getJsonData = () => {
    return this._dataService.enrollmentForm.getRawValue();
  };

  getNumberOfImages = () => {
    let counter = 0;
    const data = this.getJsonData();
    if (data.users) {
      for (let i = 0; i < data.users.length; i++) {
        const user = data.users.at(i);
        if (user.ci.fronte) counter += 1;
        if (user.ci.retro) counter += 1;
      }
    }
    return counter;
  };

  ngOnInit() {
    this.mainProcess();
  }

  async processDelay(): Promise<boolean> {
    return new Promise(async (resolve) => {
      setTimeout(() => resolve(true), 2000);
    });
  }

  async compressAllImages() {
    for (let i = 0; i < this.data.users.length; i++) {
      const user = this.data.users[i];
      if (user.ci.fronte) {
        console.info('Comprimo il fronte CI per user', i);
        const compressedImage = await this._toolsService.compressBase64Image(
          user.ci.fronte
        );
        this.data.users.at(i).ci.fronte = compressedImage;
        await this.processDelay();
        this.step += 1;
      }
      if (user.ci.retro) {
        console.info('Comprimo il retro CI per user', i);
        const compressedImage = await this._toolsService.compressBase64Image(
          user.ci.retro
        );
        this.data.users.at(i).ci.retro = compressedImage;
        await this.processDelay();
        this.step += 1;
      }
    }
  }

  printJsonSize(obj: any) {
    const jsonString = JSON.stringify(obj);
    const byteSize = new Blob([jsonString]).size;
    console.log('JSON:', byteSize, 'byte');
  }

  async mainProcess() {
    this.message = 'Recupero delle informazioni in corso...';
    this.steps = 0;
    this.step = 0;
    // Imposto i dati e gli step
    this.data = this.getJsonData();
    this.steps += 1; // Recupero delle informazioni
    this.steps += 1; // Normalizzazione dei dati
    this.steps += 1; // Elaborazione delle immagini
    this.steps += this.getNumberOfImages(); // Numero di immagini da elaborare
    this.steps += 1; // Invio dei dati
    await this.processDelay();
    this.step += 1;
    // Avvio il processo principale
    this.printJsonSize(this.data);
    this.message = 'Controllo e normalizzazione dei dati...';
    const checkAndFixData = async () => {
      return new Promise((resolve) => {
        setTimeout(() => resolve(true), 2000);
      });
    };
    await checkAndFixData();
    this.step += 1;
    this.message = 'Compressione delle immagini in corso...';
    await this.compressAllImages();
    this.printJsonSize(this.data);
    await this.processDelay();
    this.step += 1;
    this.message = 'Invio dei dati in corso...';
    this._enrollmentService.postEnrollmentForm(undefined, this.data).subscribe({
      next: async (response) => {
        console.info('DATA:', response.data);
        await this.processDelay();
        this.step += 1;
        this.message = 'Operazione completata';
        await this.processDelay();
        await this.processDelay();
        const students = this._dataService.enrollmentForm
          .getRawValue()
          .users.filter((u: any) => u.__type === 'Student');
        const studentName =
          students.length === 1
            ? `${students[0].cognome} ${students[0].nome}`.trim()
            : '';
        const enrollmentClass = `${
          this._dataService.enrollmentForm.getRawValue().anno
        }° ${this._dataService.enrollmentForm.getRawValue().dipartimento} (${
          this._dataService.enrollmentForm.getRawValue().anno_scolastico
        })`;
        this._router.navigateByUrl(
          `/success?hash=${response.data.enrollment.short_hash}&name=${studentName}&class=${enrollmentClass}`
        );
      },
      error: (err) => {
        this.message = 'Attenzione: si è verificato un errore!';
        console.error('Error:', err);
      },
    });
  }

  retry() {
    this.mainProcess();
  }
}
