<app-container>
  <div
    class="tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-min-h-[var(--dea-wrapper-height)]">
    <div *ngIf="hash !== undefined">
      <div *ngIf="hash?.length === 0">
        <div>Attenzione: sembra che si sia verificato un errore</div>
      </div>
      <div *ngIf="hash.length > 0" class="tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center">
        <div class="tw-relative tw-w-32 tw-h-32">
          <img src="/assets/success.gif" height="128" width="128"
            class="tw-absolute tw-object-contain tw-object-center" />
          <div class="tw-absolute tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-end tw-items-start">
            <div class="tw-bg-white tw-h-1 tw-w-1/3"></div>
          </div>
        </div>
        <div class="tw-text-base sm:tw-text-lg md:tw-text-2xl tw-text-center">Iscrizione completata con successo!</div>
        <div class="tw-border tw-rounded tw-bg-neutral-100 tw-p-4">
          <div *ngIf="studentName" class="tw-text-base sm:tw-text-lg md:tw-text-xl tw-text-center tw-font-bold">
            {{studentName}}</div>
          <div *ngIf="enrollmentClass" class="tw-text-sm sm:tw-text-base md:tw-text-lg tw-text-center">
            {{enrollmentClass}}
          </div>
        </div>
        <div class="tw-my-4">
          <div class="tw-mb-2 tw-text-sm tw-text-center">Comunica questo codice alla segreteria e all'amministrazione
          </div>
          <div class="tw-flex tw-flex-row tw-gap-2 tw-justify-center tw-items-center">
            <div *ngFor="let character of hash.split('');"
              class="tw-border tw-rounded tw-bg-neutral-100 tw-font-mono tw-py-3 tw-px-2 sm:tw-p-3 tw-text-base sm:tw-text-lg md:tw-text-3xl">
              {{character}}
            </div>
          </div>
        </div>
        <div class="tw-font-medium">Grazie</div>
        <div class="tw-mt-8 tw-underline tw-cursor-pointer tw-text-sm" (click)="goToHomepage($event)">
          Torna alla Homepage
        </div>
      </div>
    </div>
  </div>
</app-container>