import { EnrollmentService } from 'src/app/services/enrollment.service';
import { DataService } from 'src/app/services/data.service';
import { Component, inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-e',
  templateUrl: './step-e.component.html',
  styleUrls: ['./step-e.component.scss'],
})
export class StepEComponent implements OnInit {
  private _enrollmentService: EnrollmentService = inject(EnrollmentService);
  private _dataService: DataService = inject(DataService);

  public src?: any;

  get dataService(): DataService {
    return this._dataService;
  }

  ngOnInit(): void {
    this._enrollmentService
      .getPdfPreview(this._dataService.enrollmentForm.getRawValue())
      .subscribe({
        next: (blobData) => {
          setTimeout(() => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              this.src = new Uint8Array(fileReader.result as ArrayBuffer);
            };
            fileReader.readAsArrayBuffer(blobData);
          }, 1000);
        },
        error: (err) => console.error('Error:', err),
      });
  }

  onLoaded(event: any): void {
    console.info('=> ', event);
  }
}
