import { PhonePrefix } from '../models/phone-prefix.interface';
import { ApiResponse } from '../models/api/api-response.model';
import imageCompression from 'browser-image-compression';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { v4 as uuidV4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  public phonePrefixes: PhonePrefix[] = [];
  constructor(private http: HttpClient) {
    if (this.phonePrefixes.length === 0) {
      this.getPhonePrefixes().subscribe({
        next: (response) => (this.phonePrefixes = response.data),
        error: (err) => {
          console.error('ToolsService - getPhonePrefixes Error:', err);
        },
      });
    }
  }

  getDataFromCF(cf?: string) {
    return this.http.get<ApiResponse>(API.dataFromCF(cf), {
      withCredentials: true,
    });
  }

  getCfFromData(data?: any) {
    return this.http.get<ApiResponse>(API.CfFromData(data), {
      withCredentials: true,
    });
  }

  getPhonePrefixes() {
    return this.http.get<ApiResponse>(API.phonePrefixes(), {
      withCredentials: false,
    });
  }

  async compressBase64Image(base64String: string): Promise<string> {
    const imageFile = this._convertBase64ToFile(
      base64String,
      `${uuidV4()}.jpg`
    );

    const options = {
      maxWidthOrHeight: 1080,
      useWebWorker: false,
      maxSizeMB: 0.5,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      const base64 = await this._convertToBase64(compressedFile);
      return base64;
    } catch (error) {
      console.error("Errore nella compressione dell'immagine:", error);
      throw error;
    }
  }

  private _convertBase64ToFile(base64String: string, fileName: string): File {
    const arr = base64String.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : '';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }

  private _convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
}
