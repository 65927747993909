import { RadioButtonInterface } from 'src/app/models/radio.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToolsService } from 'src/app/services/tools.service';
import { DataService } from 'src/app/services/data.service';
import { Component, inject, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent {
  private _toolService: ToolsService = inject(ToolsService);
  private _dataService: DataService = inject(DataService);
  @Input() uuid!: string;

  get dataService(): DataService {
    return this._dataService;
  }

  get user() {
    return this._dataService.getUserByUUID(this.uuid);
  }

  get types(): RadioButtonInterface[] {
    return [
      { value: 'Parent', label: 'Genitore' },
      { value: 'Tutor', label: 'Tutore' },
    ];
  }

  get genders(): RadioButtonInterface[] {
    return [
      { value: 'M', label: 'Maschio' },
      { value: 'F', label: 'Femmina' },
    ];
  }

  get emailLabels(): RadioButtonInterface[] {
    return [
      { value: 'Personale', label: 'Personale' },
      { value: 'Lavoro', label: 'Lavoro' },
    ];
  }

  get phoneLabels(): RadioButtonInterface[] {
    return [
      { value: 'Cellulare', label: 'Cellulare' },
      { value: 'Lavoro', label: 'Lavoro' },
      { value: 'Casa', label: 'Casa' },
    ];
  }

  get phonePrefixesOptions(): { name: string; value: string }[] {
    return this._toolService.phonePrefixes
      .sort((p1, p2) => {
        if (p1.dialCode === '+39') return -1;
        if (p2.dialCode === '+39') return 1;
        return parseInt(p1.dialCode.slice(1)) - parseInt(p2.dialCode.slice(1));
      })
      .map((prefix) => {
        return {
          code: prefix.code,
          value: prefix.dialCode,
          name: `${prefix.flag} (${prefix.code}) ${prefix.dialCode}`,
        };
      });
  }

  // CF-GENERATOR
  public visible: boolean = false;
  public cfGeneratorError?: string;
  public cfGeneratorLoading: boolean = false;
  private _cfGeneratorsSub?: Subscription;
  public cfGeneratorForm: FormGroup = new FormGroup({
    cognome: new FormControl(undefined, Validators.required),
    nome: new FormControl(undefined, Validators.required),
    sesso: new FormControl(undefined, Validators.required),
    data_di_nascita: new FormControl(undefined, Validators.required),
    luogo_di_nascita: new FormControl(undefined, Validators.required),
    provincia_di_nascita: new FormControl(undefined, Validators.required),
  });

  showCfGenerator(): void {
    this.cfGeneratorForm
      .get('cognome')
      ?.setValue(this.user.get('cognome')?.value ?? undefined);
    this.cfGeneratorForm
      .get('nome')
      ?.setValue(this.user.get('nome')?.value ?? undefined);
    this.cfGeneratorForm
      .get('sesso')
      ?.setValue(this.user.get('sesso')?.value ?? undefined);
    this.cfGeneratorForm
      .get('data_di_nascita')
      ?.setValue(this.user.get('data_di_nascita')?.value ?? undefined);
    this.cfGeneratorForm
      .get('luogo_di_nascita')
      ?.setValue(this.user.get('luogo_di_nascita')?.value ?? undefined);
    this.cfGeneratorForm
      .get('provincia_di_nascita')
      ?.setValue(this.user.get('provincia_di_nascita')?.value ?? undefined);

    this._cfGeneratorsSub = this.cfGeneratorForm.valueChanges.subscribe((_) => {
      this.user
        .get('cognome')
        ?.setValue(this.cfGeneratorForm.get('cognome')?.value ?? undefined);
      this.user
        .get('nome')
        ?.setValue(this.cfGeneratorForm.get('nome')?.value ?? undefined);
      this.user
        .get('sesso')
        ?.setValue(this.cfGeneratorForm.get('sesso')?.value ?? undefined);
      this.user
        .get('data_di_nascita')
        ?.setValue(
          this.cfGeneratorForm.get('data_di_nascita')?.value ?? undefined
        );
      this.user
        .get('luogo_di_nascita')
        ?.setValue(
          this.cfGeneratorForm.get('luogo_di_nascita')?.value ?? undefined
        );
      this.user
        .get('provincia_di_nascita')
        ?.setValue(
          this.cfGeneratorForm.get('provincia_di_nascita')?.value ?? undefined
        );
    });

    this.visible = true;
  }

  onHideCfGenerator(): void {
    this.visible = false;
    this._cfGeneratorsSub?.unsubscribe();
    this.cfGeneratorForm.reset();
  }

  generateCf(): void {
    this.cfGeneratorLoading = true;
    this.cfGeneratorError = undefined;
    Object.keys(this.cfGeneratorForm.controls).map((key) =>
      this.cfGeneratorForm.get(key)?.markAsDirty()
    );
    if (!this.cfGeneratorForm.valid) return;
    this._toolService
      .getCfFromData(this.cfGeneratorForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.user
            .get('codice_fiscale')
            ?.setValue(response?.data?.codice_fiscale ?? undefined);
          this.visible = false;
        },
        error: (error) => {
          console.error('Error:', error);
          this.cfGeneratorError =
            'Non è stato possibile generare un Codice Fiscale valido, verifica i dati inseriti e riprova.';
        },
      })
      .add(() => (this.cfGeneratorLoading = false));
  }
}
