import { DataService } from 'src/app/services/data.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-step-d',
  templateUrl: './step-d.component.html',
  styleUrls: ['./step-d.component.scss'],
})
export class StepDComponent {
  get dataService(): DataService {
    return this._dataService;
  }

  constructor(private _dataService: DataService) {}

  public dialogTitle: string = '';
  public visible: boolean = false;
  public src: string = '';

  showPdfDialog(type: 'statuto' | 'regolamento'): void {
    switch (type) {
      case 'statuto':
        this.dialogTitle = 'Statuto ASD IDEA';
        this.src = 'https://static.deamicis.cloud/docs/statuto-asd-idea.pdf';
        break;
      case 'regolamento':
        this.dialogTitle = 'Regolamento Interno di Istituto';
        this.src =
          'https://static.deamicis.cloud/docs/regolamento-interno-alunni.pdf';
        break;
    }
    this.visible = true;
  }
}
