import {
  AfterContentInit,
  ContentChild,
  ElementRef,
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-step-wrapper',
  template: `<div
    class="tw-w-full tw-h-full tw-shadow-md tw-rounded-md tw-p-4 md:tw-p-8 tw-bg-white tw-border tw-border-neutral-50"
  >
    <app-step-block-title>{{ title || '' }}</app-step-block-title>
    <app-hr></app-hr>
    <ng-content></ng-content>
    <app-hr></app-hr>
    <ng-content select="[controls]"></ng-content>
    <app-step-block-controller *ngIf="!hasControls"></app-step-block-controller>
  </div>`,
})
export class StepWrapperComponent implements AfterContentInit {
  @ContentChild('controls', { read: ElementRef }) controlsContent!: ElementRef;
  @Input() title: string | undefined;

  public hasControls: boolean = false;

  ngAfterContentInit() {
    this.hasControls = !!this.controlsContent;
  }
}
