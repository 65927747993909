<div class="tw-w-screen tw-mb-8" style="background-color: var(--dea-primary-color); height: var(--dea-navbar-height);"
  [style]="env.type != 'prod' ? 'background-color: greenyellow;' : ''">
  <app-container>
    <div class="tw-w-full tw-h-full tw-flex tw-flex-row tw-justify-between">
      <div class="tw-my-auto tw-flex tw-flex-row tw-items-end tw-gap-4">
        <img src="{{src}}" alt="Istituti De Amicis" class="tw-h-10">
      </div>
      <div class="tw-my-auto tw-hidden sm:tw-block">
        <!-- <img src="https://static.deamicis.cloud/ring/ring-icon-w.png" alt="De Amicis Ring" class="tw-h-12"> -->
      </div>
    </div>
  </app-container>
</div>
