import { RadioButtonInterface } from 'src/app/models/radio.interface';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step-a',
  templateUrl: './step-a.component.html',
  styleUrls: ['./step-a.component.scss'],
})
export class StepAComponent implements OnInit, OnDestroy {
  get dataService(): DataService {
    return this._dataService;
  }

  readonly scholasticYearsListing: RadioButtonInterface[] = [
    {
      value: ScholasticYear.getCurrent(),
      label: ScholasticYear.getCurrent(),
      description: 'Anno Scolastico in corso',
    },
    {
      value: ScholasticYear.getNext(),
      label: ScholasticYear.getNext(),
      description: 'Prossimo Anno Scolastico',
    },
    // {
    //   value: ScholasticYear.getFromCurrent(2),
    //   label: ScholasticYear.getFromCurrent(2),
    //   description: 'Preiscrizione per Studenti di 2° Media',
    // },
  ];

  readonly lastYearOptions: RadioButtonInterface[] = [
    { value: 'Classe', label: 'Classe' },
    { value: 'Esame', label: "Esame d'idoneità / Esame di Stato" },
  ];

  readonly resultsListing: string[] = [
    'Ammesso',
    'Non Ammesso',
    'Diplomato',
    'Ritirato',
  ];

  private _subscriptions: Subscription[] = [];
  public detailsPlaceholder: string = '';
  public detailsLabel: string = 'Dettagli';

  constructor(private _dataService: DataService) {}

  ngOnInit(): void {
    const lastYearTypeSub = this.dataService.enrollmentForm
      ?.get('ultimo_anno')
      ?.get('tipo')
      ?.valueChanges.subscribe((data: string) => {
        if (data.toLowerCase() === 'classe') {
          this.detailsLabel = 'Dettagli ultima classe frequentata';
          this.detailsPlaceholder = 'Classe + Indirizzo di studi';
        } else if (data.toLowerCase() === 'esame') {
          this.detailsLabel = 'Dettagli ultimo esame sostenuto';
          this.detailsPlaceholder = 'Tipo esame';
        }
      });
    if (lastYearTypeSub) this._subscriptions.push(lastYearTypeSub);
  }

  ngOnDestroy(): void {
    this._subscriptions?.map((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
