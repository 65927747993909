<app-container>
  <div
    class="tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-min-h-[var(--dea-wrapper-height)]">
    <img src="https://static.deamicis.cloud/logo/dea-logo-b.png" alt="Istituti De Amicis"
      class="tw-h-20 tw-object-contain tw-object-center" />
    <div class="tw-font-medium tw-text-center">Modulo per domanda di iscrizione studenti</div>
    <div class="tw-mt-6">
      <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
        [routerLink]="'/informazioni'">
        <span class="tw-px-8 tw-py-4" style="color: inherit;">
          <strong style="color: inherit;">ISCRIVITI</strong>
        </span>
      </button>
    </div>
  </div>
</app-container>
